import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
import PubSub from "pubsub-js";
import { EventType, WalletType } from "../types";
import { BTCWalletKitModal } from "..";
import { request, RpcErrorCode } from "sats-connect";

interface UniSatWallet {
  requestAccounts: () => Promise<string[]>;
  signPsbt: (psbtHex: string) => Promise<string>;
  pushPsbt: (signedPsbt: string) => Promise<string>;
  on: (event: string, callback: (data: any) => void) => void;
}

interface OkxWallet {
  bitcoin: {
    connect: () => Promise<{ address: string; publicKey: string }>;
    requestAccounts: () => Promise<string[]>;
    on: (event: string, callback: (data: any) => void) => void;
    signPsbt: (psbtHex: string) => Promise<string>;
    pushPsbt: (signedPsbt: string) => Promise<string>;
  };
}

declare global {
  interface Window {
    unisat?: UniSatWallet;
    okxwallet?: OkxWallet;
  }
}

interface TransferRunesParams {
  wallet_address: string;
  receiver_address: string;
  rune_name: string;
  fee_rate: number;
  amount: string;
}

interface BTCWalletKitProviderProps {
  address: string;
  connector?: WalletType;
  onShowModal: () => void;
  onHideModal: () => void;
  onDisconnect: () => void;
  transferRunes: ({
    wallet_address,
    receiver_address,
    rune_name,
    fee_rate,
    amount,
  }: TransferRunesParams) => Promise<string>;
}

const BTCWalletKitProviderContext =
  createContext<BTCWalletKitProviderProps | null>(null);

const accountAtom = atomWithStorage<{
  address: string;
  connector?: WalletType;
}>("bwk.account", {
  address: "",
  connector: undefined,
});

export function BTCWalletProvider({
  children,
  autoConnect,
}: {
  children: ReactNode;
  autoConnect?: boolean;
}) {
  const [account, setAccount] = useAtom(accountAtom);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (account.connector === WalletType.OKX) {
      window.okxwallet?.bitcoin?.on("accountsChanged", (accounts) => {
        if (accounts.length) {
          setAccount((prev) => ({
            ...prev,
            address: accounts[0],
            connector: WalletType.OKX,
          }));
        }
      });
    } else if (account.connector === WalletType.Unisat) {
      window.unisat?.on("accountsChanged", (accounts) => {
        if (accounts.length) {
          setAccount((prev) => ({
            ...prev,
            address: accounts[0],
            connector: WalletType.OKX,
          }));
        }
      });
    }
  }, [account.connector]);

  useEffect(() => {
    const connectListener = PubSub?.subscribe(
      EventType.ON_CONNECT,
      (
        _: string,
        { address, connector }: { address: string; connector: WalletType },
      ) => {
        setAccount({ address, connector });
      },
    );
    const inputListener = PubSub?.subscribe(
      EventType.ON_MANUAL_INPUT,
      (_: string, { address }: { address: string }) => {
        setAccount({ address, connector: undefined });
      },
    );
    return () => {
      PubSub?.unsubscribe(connectListener);
      PubSub?.unsubscribe(inputListener);
    };
  }, [setAccount]);

  useEffect(() => {
    if (autoConnect) {
      if (account.connector === WalletType.OKX) {
        window.okxwallet?.bitcoin?.requestAccounts().then((accounts) => {
          if (!accounts.some((acc) => acc === account.address)) {
            setAccount({
              address: "",
              connector: undefined,
            });
          }
        });
      } else if (account.connector === WalletType.Unisat) {
        window.unisat?.requestAccounts().then((accounts) => {
          if (!accounts.some((acc) => acc === account.address)) {
            setAccount({
              address: "",
              connector: undefined,
            });
          }
        });
      }
    }
  }, [account.address, account.connector, autoConnect]);

  const contextValue = useMemo(
    () => ({
      address: account.address,
      connector: account.connector,
      onShowModal: () => {
        setShowModal(true);
      },
      onHideModal: () => {
        setShowModal(false);
      },
      onDisconnect: () => {
        if (account) {
          if (account.connector === WalletType.Xverse) {
            //
          }
        }
        setAccount({ address: "", connector: undefined });
      },
      transferRunes: async (params: TransferRunesParams) => {
        if (!account.connector) {
          throw new Error("Please connect wallet first");
        }
        if (account.connector === WalletType.Xverse) {
          const sendParams = {
            runeName: params.rune_name,
            amount: String(params.amount),
            address: params.receiver_address,
          };
          const response = await request("runes_transfer", {
            recipients: [sendParams],
          });

          if (response.status === "success") {
            return response.result?.txid ?? "";
          } else {
            if (response.error.code === RpcErrorCode.USER_REJECTION) {
              throw new Error("User Rejected");
            } else {
              throw new Error("Failed to sign psbt");
            }
          }
        } else {
          const result = await fetch(
            "https://runemint.mainnet.octopus.network/send",
            {
              method: "POST",
              body: JSON.stringify(params),
              headers: {
                "Content-Type": "application/json",
              },
            },
          ).then((res) => res.json());

          let psbtHex;
          if (result.code === 0) {
            psbtHex = result.data;
          } else {
            throw new Error(result.data);
          }

          if (account.connector === WalletType.OKX) {
            const _account = await window.okxwallet?.bitcoin?.connect();
            if (_account?.address !== account.address) {
              throw new Error(
                "The connected account doesn't match the current account",
              );
            }
            const signedPsbt =
              await window.okxwallet?.bitcoin?.signPsbt(psbtHex);
            if (signedPsbt) {
              return window.okxwallet?.bitcoin?.pushPsbt(signedPsbt) || "";
            }
          } else if (account.connector === WalletType.Unisat) {
            const _accounts = await window.unisat?.requestAccounts();
            if (
              !_accounts ||
              !_accounts.some((acc) => acc === account.address)
            ) {
              throw new Error(
                "The connected account doesn't match the current account",
              );
            }
            const signedPsbt = await window.unisat?.signPsbt(psbtHex);
            if (signedPsbt) {
              return window.unisat?.pushPsbt(signedPsbt) || "";
            }
          }
        }

        throw new Error("Unsupported wallet type");
      },
    }),
    [account.address, account.connector, setAccount],
  );
  return (
    <BTCWalletKitProviderContext.Provider value={contextValue}>
      {children}
      <BTCWalletKitModal open={showModal} onClose={contextValue.onHideModal} />
    </BTCWalletKitProviderContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useBTCWalletKit() {
  const context = useContext(BTCWalletKitProviderContext);

  if (!context) {
    throw new Error(
      "useBTCWalletKit must be used within a BTCWalletKitContextProvider",
    );
  }

  return context;
}
