import { ChainName } from "../types";
import { BTCAddress } from "@wallet-kits/btc-wallet-kit";
import { ICPAddress } from "@wallet-kits/icp-wallet-kit";
import EvmAddress from "@wallet-kits/evm-wallet-kit/EvmAddress";
import { isEvmChain } from "../utils/chains";
import SolAddress from "@wallet-kits/sol-wallet-kit/SolAddress";

export default function CurrentWallet({
  chain,
  isSource = false,
}: {
  chain?: ChainName;
  isSource?: boolean;
}) {
  if (!chain) {
    return null;
  }
  if (chain === ChainName.Bitcoin) {
    return <BTCAddress />;
  }

  if (chain === ChainName.ICP) {
    return <ICPAddress />;
  }

  if (chain === ChainName.Solana) {
    return <SolAddress />;
  }

  if (isEvmChain(chain)) {
    return <EvmAddress isSource={isSource} />;
  }

  return null;
}
