import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
import PubSub from "pubsub-js";
import { EventType, WalletType } from "../types";
import ICPWalletKitModal from "./ICPWalletKitModal";
import { Actor, ActorSubclass, HttpAgent } from "@dfinity/agent";
import { StoicIdentity } from "ic-stoic-identity";
import { IDL } from "@dfinity/candid";
import { createActor } from "@services/candids";
import { AccountIdentifier, LedgerCanister } from "@dfinity/ledger-icp";
import { Principal } from "@dfinity/principal";

interface Token {
  amount: number;
  currency: string;
  image: string;
  name: string;
  value: number;
}

declare global {
  interface Window {
    ic: {
      plug?: {
        requestConnect: ({
          whitelist,
          host,
        }: {
          whitelist?: string[];
          host?: string;
        }) => Promise<string>;
        principalId?: string;
        requestBalance: () => Promise<Token[]>;
        agent: HttpAgent;
        isConnected: () => Promise<boolean>;
        requestTransfer: (params: {
          to: String;
          amount: number;
          opts?: {
            fee?: number;
            memo?: string;
            from_subaccount?: Number;
            created_at_time?: {
              timestamp_nanos: number;
            };
          };
        }) => Promise<{ height: number }>;
        createActor: <T>(params: any) => any;
        sessionManager: {
          sessionData: {
            principalId: string;
            agent: HttpAgent;
          };
        };
      };
    };
  }
}

interface ICPWalletKitProviderProps {
  address: string;
  connector?: WalletType;
  onShowModal: (params: { whitelist: string[] }) => void;
  onHideModal: () => void;
  onDisconnect: () => void;
  createActor: <T>(
    canisterId: string,
    interfaceFactory: IDL.InterfaceFactory,
  ) => Promise<ActorSubclass<T>>;
  transfer: (params: {
    to: string;
    amount: bigint;
  }) => Promise<bigint | number | undefined>;
}

const ICPWalletKitProviderContext =
  createContext<ICPWalletKitProviderProps | null>(null);

const accountAtom = atomWithStorage<{
  address: string;
  connector?: WalletType;
}>("iwk.account", {
  address: "",
  connector: undefined,
});

export function ICPWalletProvider({ children }: { children: ReactNode }) {
  const [account, setAccount] = useAtom(accountAtom);
  const [showModal, setShowModal] = useState(false);
  const [whitelist, setWhitelist] = useState<string[]>([]);

  useEffect(() => {
    const connectListener = PubSub?.subscribe(
      EventType.ON_CONNECT,
      (
        _: string,
        { address, connector }: { address: string; connector: WalletType },
      ) => {
        setAccount({ address, connector });
      },
    );
    const inputListener = PubSub?.subscribe(
      EventType.ON_MANUAL_INPUT,
      (_: string, { address }: { address: string }) => {
        setAccount({ address, connector: undefined });
      },
    );
    return () => {
      PubSub?.unsubscribe(connectListener);
      PubSub?.unsubscribe(inputListener);
    };
  }, [setAccount]);

  // Stoic Wallet: https://iloveics.gitbook.io/icpswap/products/wallet/stoic-wallet-cookies-problem-solution
  const contextValue = useMemo(
    () => ({
      address: account.address,
      connector: account.connector,
      onShowModal: ({ whitelist }: { whitelist: string[] }) => {
        setShowModal(true);
        setWhitelist(whitelist);
      },
      onHideModal: () => {
        setShowModal(false);
      },
      onDisconnect: () => {
        if (account.connector === WalletType.Stoic) {
          StoicIdentity.disconnect();
        }
        setAccount({ address: "", connector: undefined });
      },
      createActor: async (
        canisterId: string,
        interfaceFactory: IDL.InterfaceFactory,
      ) => {
        if (account.connector === WalletType.Stoic) {
          throw new Error("Stoic wallet is not supported anymore");
        } else if (account.connector === WalletType.Plug) {
          await window.ic.plug?.requestConnect({
            whitelist: [canisterId],
          });
          const agent = window.ic?.plug?.agent;
          return window.ic?.plug?.createActor({
            interfaceFactory,
            canisterId: canisterId,
            agent,
          });
        }
        throw new Error("No wallet connected");
      },
      transfer: async (params: { to: string; amount: bigint }) => {
        if (account.connector === WalletType.Plug) {
          const result = await window.ic?.plug?.requestTransfer({
            to: params.to,
            amount: Number(params.amount.toString()),
          });
          return result?.height;
        } else if (account.connector === WalletType.Stoic) {
          throw new Error("Stoic wallet is not supported anymore");
        }
        throw new Error("No wallet connected");
      },
    }),
    [account.address, account.connector, setAccount],
  );

  return (
    <ICPWalletKitProviderContext.Provider value={contextValue}>
      {children}
      <ICPWalletKitModal
        open={showModal}
        onClose={contextValue.onHideModal}
        whitelist={whitelist}
      />
    </ICPWalletKitProviderContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useICPWalletKit() {
  const context = useContext(ICPWalletKitProviderContext);

  if (!context) {
    throw new Error(
      "useICPWalletKit must be used within a ICPWalletKitContextProvider",
    );
  }

  return context;
}
