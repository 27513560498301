/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
import PubSub from "pubsub-js";
import { EventType, WalletType } from "../types";
import { BTCWalletKitModal } from "..";
import { formatUnits } from "../../../utils/format";
import { BTC_DECIMALS } from "../../../utils/constants";

interface UniSatWallet {
  requestAccounts: () => Promise<string[]>;
  signPsbt: (psbtHex: string) => Promise<string>;
  pushPsbt: (signedPsbt: string) => Promise<string>;
  on: (event: string, callback: (data: any) => void) => void;
  getBalance: () => Promise<BTCBalance>;
  sendBitcoin: (to: string, amount: number) => Promise<string>;
}

interface OkxWallet {
  bitcoin: {
    connect: () => Promise<{ address: string; publicKey: string }>;
    requestAccounts: () => Promise<string[]>;
    getBalance: () => Promise<BTCBalance>;
    on: (event: string, callback: (data: any) => void) => void;
    signPsbt: (psbtHex: string) => Promise<string>;
    pushPsbt: (signedPsbt: string) => Promise<string>;
    send: (params: {
      from: string;
      to: string;
      value: string;
    }) => Promise<{ txhash: string }>;
  };
}

declare global {
  interface Window {
    unisat?: UniSatWallet;
    okxwallet?: OkxWallet;
  }
}

interface BTCBalance {
  confirmed: number;
  unconfirmed: number;
  total: number;
}

interface BTCWalletKitProviderProps {
  address: string;
  balance?: BTCBalance;
  connector?: WalletType;
  onShowModal: () => void;
  onHideModal: () => void;
  onDisconnect: () => void;
  signPsbt: (psbtHex: string) => Promise<string>;
  pushPsbt: (signedPsbt: string) => Promise<string>;
  send: (to: string, amount: number) => Promise<string | undefined>;
}

const BTCWalletKitProviderContext =
  createContext<BTCWalletKitProviderProps | null>(null);

const accountAtom = atomWithStorage<{
  address: string;
  connector?: WalletType;
  balance?: BTCBalance;
}>("bwk.account", {
  address: "",
  connector: undefined,
  balance: undefined,
});

export function BTCWalletProvider({
  children,
  autoConnect,
}: {
  children: ReactNode;
  autoConnect?: boolean;
}) {
  const [account, setAccount] = useAtom(accountAtom);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (account.address) {
      if (account.connector === WalletType.OKX) {
        window.okxwallet?.bitcoin
          .getBalance()
          .then((balance) => {
            setAccount((prev) => ({
              ...prev,
              balance,
            }));
          })
          .catch(console.error);
      } else if (account.connector === WalletType.Unisat) {
        window.unisat
          ?.getBalance()
          .then((balance) => {
            setAccount((prev) => ({
              ...prev,
              balance,
            }));
          })
          .catch(console.error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.address, account.connector]);

  useEffect(() => {
    if (account.connector === WalletType.OKX) {
      window.okxwallet?.bitcoin?.on("accountsChanged", (accounts) => {
        if (accounts.length) {
          setAccount((prev) => ({
            ...prev,
            address: accounts[0],
            connector: WalletType.OKX,
          }));
        }
      });
    }
    if (account.connector === WalletType.Unisat) {
      window.unisat?.on("accountsChanged", (accounts) => {
        if (accounts.length) {
          setAccount((prev) => ({
            ...prev,
            address: accounts[0],
            connector: WalletType.OKX,
          }));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.connector]);

  useEffect(() => {
    const connectListener = PubSub?.subscribe(
      EventType.ON_CONNECT,
      (
        _: string,
        { address, connector }: { address: string; connector: WalletType },
      ) => {
        setAccount({ address, connector });
      },
    );
    const inputListener = PubSub?.subscribe(
      EventType.ON_MANUAL_INPUT,
      (_: string, { address }: { address: string }) => {
        setAccount({ address, connector: undefined });
      },
    );
    return () => {
      PubSub?.unsubscribe(connectListener);
      PubSub?.unsubscribe(inputListener);
    };
  }, [setAccount]);

  useEffect(() => {
    if (autoConnect) {
      if (account.connector === WalletType.OKX) {
        window.okxwallet?.bitcoin?.requestAccounts().then((accounts) => {
          if (!accounts.some((acc) => acc === account.address)) {
            setAccount({
              address: "",
              connector: undefined,
            });
          }
        });
      } else if (account.connector === WalletType.Unisat) {
        window.unisat?.requestAccounts().then((accounts) => {
          if (!accounts.some((acc) => acc === account.address)) {
            setAccount({
              address: "",
              connector: undefined,
            });
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.address, account.connector, autoConnect]);

  const contextValue = useMemo(
    () => ({
      address: account.address,
      connector: account.connector,
      balance: account.balance,
      onShowModal: () => {
        setShowModal(true);
      },
      onHideModal: () => {
        setShowModal(false);
      },
      onDisconnect: () => {
        setAccount({ address: "", connector: undefined, balance: undefined });
      },
      send: async (to: string, amount: number) => {
        if (!account.connector || !account.address) {
          throw new Error("Please connect wallet first");
        }
        if (account.connector === WalletType.OKX) {
          const result = await window.okxwallet?.bitcoin.send({
            from: account.address,
            to,
            value: formatUnits(BigInt(amount), BTC_DECIMALS),
          });
          return result?.txhash;
        } else if (account.connector === WalletType.Unisat) {
          const txid = await window.unisat?.sendBitcoin(to, amount);
          return txid;
        }
      },
      signPsbt: async (psbtHex: string) => {
        if (!account.connector) {
          throw new Error("Please connect wallet first");
        }
        if (account.connector === WalletType.OKX) {
          const _account = await window.okxwallet?.bitcoin?.connect();
          if (_account?.address !== account.address) {
            throw new Error(
              "The connected account doesn't match the current account",
            );
          }
          return window.okxwallet?.bitcoin?.signPsbt(psbtHex) || "";
        } else if (account.connector === WalletType.Unisat) {
          const _accounts = await window.unisat?.requestAccounts();
          if (!_accounts || !_accounts.some((acc) => acc === account.address)) {
            throw new Error(
              "The connected account doesn't match the current account",
            );
          }
          return window.unisat?.signPsbt(psbtHex) || "";
        }
        return "";
      },
      pushPsbt: async (signedPsbt: string) => {
        if (!account.connector) {
          throw new Error("Please connect wallet first");
        }
        if (account.connector === WalletType.OKX) {
          return window.okxwallet?.bitcoin?.pushPsbt(signedPsbt) || "";
        } else if (account.connector === WalletType.Unisat) {
          return window.unisat?.pushPsbt(signedPsbt) || "";
        }
        return "";
      },
    }),
    [account.address, account.connector, account.balance, setAccount],
  );
  return (
    <BTCWalletKitProviderContext.Provider value={contextValue}>
      {children}
      <BTCWalletKitModal open={showModal} onClose={contextValue.onHideModal} />
    </BTCWalletKitProviderContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useBTCWalletKit() {
  const context = useContext(BTCWalletKitProviderContext);

  if (!context) {
    throw new Error(
      "useBTCWalletKit must be used within a BTCWalletKitContextProvider",
    );
  }

  return context;
}
