import {
  HStack,
  VStack,
  Text,
  Input,
  useColorModeValue,
  Link,
  chakra,
} from "@chakra-ui/react";
import ChainButton from "@components/ChainButton";
import CurrentWallet from "@components/CurrentWallet";
import { useBurnContext } from "./context/BurnContext";
import TokenSelector from "@components/TokenSelector";
import TokenBalance from "@components/TokenBalance";
import SubmitBurn from "@components/SubmitButton/SubmitBurn";
import BurnHistory from "@components/BurnHistory";
import { ArrowUpRight } from "lucide-react";

const ArrowUpRightIcon = chakra(ArrowUpRight);

export default function Burn({ width }: { width?: number }) {
  const { burnChain, token, onTokenChange, amount, onAmountChange } =
    useBurnContext();
  const boxWrapperBg = useColorModeValue("#eee", "gray.700");
  const inputBoxWrapperBg = useColorModeValue("white", "gray.800");
  const inputTextColor = useColorModeValue("gray.800", "gray.200");
  const textColor = useColorModeValue("#333", "#eee");

  return (
    <VStack w={{ base: "100%", md: width }} gap={6}>
      <VStack
        width="100%"
        p={{ base: 4, md: 6 }}
        borderRadius={8}
        gap={4}
        bg={inputBoxWrapperBg}
        color={textColor}
      >
        <VStack w="100%" alignItems="flex-start" gap={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={600}>L2 Chain</Text>
            <CurrentWallet chain={burnChain} />
          </HStack>
          <ChainButton chainName={burnChain} />
        </VStack>

        <VStack w="100%" alignItems="flex-start" gap={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={600}>Token</Text>
            {token && (
              <Link
                href={`https://www.runescan.net/runes/${token.name}`}
                target="_blank"
                color="blue.400"
                fontSize={12}
              >
                <HStack gap={0}>
                  <Text>View on Runescan</Text>
                  <ArrowUpRightIcon size={16} strokeWidth={1} />
                </HStack>
              </Link>
            )}
          </HStack>
          <VStack w="100%" bg={boxWrapperBg} borderRadius={4} p={4}>
            <HStack w="100%" py={2}>
              <Input
                placeholder="0"
                color={inputTextColor}
                flex={1}
                value={amount}
                fontSize={60 * (amount.length > 6 ? 0.8 : 1)}
                py={`${amount.length > 6 ? 6 : 0}px`}
                lineHeight={1}
                px={0}
                fontWeight={600}
                borderWidth={0}
                height="100%"
                outline="none"
                _focus={{
                  borderWidth: 0,
                  outline: "none",
                  boxShadow: "none",
                }}
                onChange={(e) => {
                  const newValue = e.target.value;
                  // fraction not longer than decimals
                  let [_, fraction] = newValue.split(".");
                  if (fraction && fraction.length > (token?.decimals ?? 0)) {
                    return;
                  }
                  if (/^\d+(\.)?(\d+)?$/.test(newValue) || newValue === "") {
                    onAmountChange(e.target.value);
                  }
                }}
              />
              <TokenSelector token={token} setToken={onTokenChange} />
            </HStack>
            <HStack
              w="100%"
              alignItems="center"
              justifyContent="flex-end"
              color="#999"
            >
              <HStack>
                <Text>Balance:</Text>
                <TokenBalance />
              </HStack>
            </HStack>
          </VStack>
          <BurnHistory token={token} />
        </VStack>
      </VStack>

      <SubmitBurn />
    </VStack>
  );
}
