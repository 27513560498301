import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  VStack,
  HStack,
  Text,
  useColorModeValue,
  Box,
  useToast,
} from "@chakra-ui/react";
import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import { useTransferContext } from "./TransferContext";
import ChainLogo from "../components/ChainLogo";
import { useHubContext } from "./OmnityHubContext";
import { ChainName, ChainState, TabAction } from "../types";
import { getAvailableChainName } from "../utils/chains";
import { useBurnContext } from "./BurnContext";
import ItemSelected from "@components/common/ItemSelected";
import CloseButtonForModal from "@components/common/CloseButtonForModal";
import { useMintContext } from "./MintContext";
import { TEMPORARY_DISABLED_CHAINS } from "src/utils/constants";

interface ChainSelectProviderProps {
  onShowChainsModal: (isSettingSourceChain: boolean) => void;
}

const initialState: ChainSelectProviderProps = {
  onShowChainsModal: () => {},
};

const ChainSelectContext =
  createContext<ChainSelectProviderProps>(initialState);

export function useChainContext() {
  return useContext(ChainSelectContext);
}

export function ChainSelectProvider({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSettingSourceChain, setIsSettingSourceChain] = useState(false);

  const {
    sourceChain,
    targetChain,
    token,
    isPeerToPeer,
    passedProps,
    onSourceChainChange,
    onTargetChainChange,
  } = useTransferContext();
  const { burnChain, onBurnChainChange } = useBurnContext();
  const { onMintChainChange } = useMintContext();

  const toast = useToast();
  const textColor = useColorModeValue("gray.800", "gray.100");
  const boxWrapperBg = useColorModeValue("gray.200", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  const context = {
    onShowChainsModal: (isSettingSourceChain: boolean) => {
      setIsSettingSourceChain(isSettingSourceChain);
      onOpen();
    },
  };

  const { chainList, chains, tabAction } = useHubContext();

  const _chainsList = useMemo(() => {
    if (tabAction === TabAction.Burn || tabAction === TabAction.Mint) {
      return chainList.filter(
        (c) => c !== ChainName.Bitcoin && c !== ChainName.Solana,
      ) as ChainName[];
    }
    if (isSettingSourceChain || !sourceChain) {
      if (isPeerToPeer) {
        return [passedProps?.sourceChain, passedProps?.targetChain].filter(
          (t) => t !== targetChain,
        ) as ChainName[];
      }
      return chainList.filter((c) => c !== targetChain) as ChainName[];
    }
    if (sourceChain) {
      if (isPeerToPeer) {
        return [passedProps?.sourceChain, passedProps?.targetChain].filter(
          (t) => t !== sourceChain,
        ) as ChainName[];
      }
      return getAvailableChainName(sourceChain, chains) as ChainName[];
    }
    return chainList.filter((c) => {
      return c !== sourceChain;
    }) as ChainName[];
  }, [
    tabAction,
    isSettingSourceChain,
    sourceChain,
    targetChain,
    chains.length,
    token?.chain_id,
    isPeerToPeer,
  ]);

  let _selected: ChainName | undefined;
  if (tabAction === TabAction.Burn) {
    _selected = burnChain;
  } else if (tabAction === TabAction.Transfer) {
    _selected = isSettingSourceChain ? sourceChain : targetChain;
  }

  return (
    <ChainSelectContext.Provider value={context}>
      {children}
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={0}
          borderRadius={8}
          color={textColor}
          margin={{ base: 0 }}
          alignSelf={{ base: "flex-end", md: "center" }}
        >
          <ModalHeader>Select chain</ModalHeader>
          <CloseButtonForModal />
          <ModalBody p={0}>
            <VStack maxH={300} overflowY="scroll" mt={2} gap={0}>
              {_chainsList
                .filter((c) => {
                  if (token) {
                    return chains
                      .find((ch) => ch.chain_name === c)
                      ?.token_list?.some((t) => t.token_id === token.token_id);
                  }
                  return true;
                })
                .sort((a: ChainName, b: ChainName) => a.localeCompare(b))
                .map((c, idx) => {
                  const isLast = idx === _chainsList.length - 1;
                  const chain = chains.find(
                    (ch) => ch.chain_name.toLowerCase() === c.toLowerCase(),
                  );
                  const isTemporaryDisabled = TEMPORARY_DISABLED_CHAINS.some(
                    (c) => c === chain?.chain_id,
                  );
                  const disabled =
                    chain?.chain_state === ChainState.Deactive ||
                    isTemporaryDisabled;

                  return (
                    <HStack
                      key={c}
                      w="100%"
                      py={4}
                      px={6}
                      cursor={disabled ? "not-allowed" : "pointer"}
                      opacity={disabled ? 0.5 : 1}
                      justifyContent="space-between"
                      borderBottomLeftRadius={isLast ? 8 : 0}
                      borderBottomRightRadius={isLast ? 8 : 0}
                      _hover={{
                        bg: boxWrapperBg,
                      }}
                      borderBottomWidth={0.5}
                      borderBottomColor={borderColor}
                      onClick={() => {
                        if (!chain) {
                          return;
                        }
                        if (disabled) {
                          return toast({
                            title: `Bitcoin can't be connected to ${c} currently`,
                            status: "error",
                            duration: 3000,
                          });
                        }
                        switch (tabAction) {
                          case TabAction.Transfer:
                            if (isSettingSourceChain) {
                              onSourceChainChange(chain.chain_name);
                            } else {
                              onTargetChainChange(chain.chain_name);
                            }
                            break;
                          case TabAction.Burn:
                            onBurnChainChange(chain.chain_name);
                            break;
                          case TabAction.Mint:
                            onMintChainChange(chain.chain_name);
                            break;
                          default:
                            break;
                        }
                        setTimeout(() => {
                          onClose();
                        }, 150);
                      }}
                    >
                      <HStack>
                        <ChainLogo chain={c} size={40} />
                        <Text fontWeight={600} fontSize={24}>
                          {c}
                        </Text>
                      </HStack>
                      {disabled ? (
                        <Box bg="red.400" color="white" px={2} borderRadius={4}>
                          <Text fontSize={12}>{ChainState.Deactive}</Text>
                        </Box>
                      ) : _selected === c ? (
                        <ItemSelected size={24} />
                      ) : null}
                    </HStack>
                  );
                })}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChainSelectContext.Provider>
  );
}
