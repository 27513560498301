import { Chain as EvmChain } from "viem";
import {
  ChainID,
  ChainName,
  IndexerTicketStatus,
  ServiceType,
  TicketAction,
} from "@types";
import {
  bevm,
  bitlayer,
  bsquared,
  bitfinity,
  ailayer,
} from "@wallet-kits/evm-wallet-kit/networks";
import {
  AILayer,
  Bevm,
  BitLayer,
  Bitcoin,
  Bitfinity,
  Bob,
  Bsquared,
  Ethereum,
  ICP,
  Merlin,
  Rootstock,
  Solana,
  XLayer,
} from "../icons";
import React from "react";
import { rootstock, bob, merlin, xLayer, mainnet } from "viem/chains";

export const FETCH_TOKEN_INTERVAL = 30000;

export const CHECK_TICKET_STATUS_INTERVAL = 10000;

export const CHECK_TX_STATUS_INTERVAL = 3000;

export const FETCH_BTC_FEE_RATE_INTERVAL = 60000;

export const ICP_DECIMALS = 8;

export const DEFAULT_TOKEN = {
  [ChainID.Bitcoin]: "Bitcoin-runes-HOPE•YOU•GET•RICH",
  [ChainID.sICP]: "sICP-native-ICP",
};

export const ADD_RUNES_SUFFIX = ".OT";

export const BADGE_COLORS = {
  [TicketAction.Mint]: "green",
  [TicketAction.Burn]: "red",
  [TicketAction.Transfer]: "blue",
  [TicketAction.Redeem]: "teal",
};

export const TICKET_STATUS_COLORS = {
  [IndexerTicketStatus.Generating]: "red.400",
  [IndexerTicketStatus.Unknown]: "pink.400",
  [IndexerTicketStatus.Pending]: "pink.400",
  [IndexerTicketStatus.WaitingForConfirmBySrc]: "orange.400",
  [IndexerTicketStatus.WaitingForConfirmByDest]: "teal.400",
  [IndexerTicketStatus.Finalized]: "green.400",
};

export const CHAIN_ID_NAME_MAP: {
  [key in ChainID]: {
    chainName: ChainName;
    evmChain?: EvmChain;
    serviceType: ServiceType;
    icon: React.ElementType;
  };
} = {
  [ChainID.Bitcoin]: {
    chainName: ChainName.Bitcoin,
    serviceType: ServiceType.Customs,
    icon: Bitcoin,
  },
  [ChainID.eICP]: {
    chainName: ChainName.ICP,
    serviceType: ServiceType.Route,
    icon: ICP,
  },
  [ChainID.BEVM]: {
    chainName: ChainName.BEVM,
    serviceType: ServiceType.Route,
    evmChain: bevm,
    icon: Bevm,
  },
  [ChainID.BitLayer]: {
    chainName: ChainName.BitLayer,
    serviceType: ServiceType.Route,
    evmChain: bitlayer,
    icon: BitLayer,
  },
  [ChainID.BSquared]: {
    chainName: ChainName.BSquared,
    serviceType: ServiceType.Route,
    evmChain: bsquared,
    icon: Bsquared,
  },
  [ChainID.XLayer]: {
    chainName: ChainName.XLayer,
    serviceType: ServiceType.Route,
    evmChain: xLayer,
    icon: XLayer,
  },
  [ChainID.Merlin]: {
    chainName: ChainName.Merlin,
    serviceType: ServiceType.Route,
    evmChain: merlin,
    icon: Merlin,
  },
  [ChainID.Bob]: {
    chainName: ChainName.Bob,
    serviceType: ServiceType.Route,
    evmChain: bob,
    icon: Bob,
  },
  [ChainID.RootStock]: {
    chainName: ChainName.Rootstock,
    serviceType: ServiceType.Route,
    evmChain: rootstock,
    icon: Rootstock,
  },
  [ChainID.Bitfinity]: {
    chainName: ChainName.Bitfinity,
    serviceType: ServiceType.Route,
    evmChain: bitfinity,
    icon: Bitfinity,
  },
  [ChainID.AILayer]: {
    chainName: ChainName.AILayer,
    serviceType: ServiceType.Route,
    evmChain: ailayer,
    icon: AILayer,
  },
  [ChainID.sICP]: {
    chainName: ChainName.ICP,
    serviceType: ServiceType.Customs,
    icon: ICP,
  },
  [ChainID.Solana]: {
    chainName: ChainName.Solana,
    serviceType: ServiceType.Route,
    icon: Solana,
  },
  [ChainID.Ethereum]: {
    chainName: ChainName.Ethereum,
    serviceType: ServiceType.Route,
    evmChain: mainnet,
    icon: Ethereum,
  },
};

export const TEMPORARY_DISABLED_CHAINS = [];
