import { EventType, WalletType } from "../../types";
import * as PubSub from "pubsub-js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  Image,
  Text,
  Badge,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { OkxWalletLogo, UnisatWalletLogo, XverseWalletLogo } from "./Logos";
import { request, AddressPurpose, RpcErrorCode } from "sats-connect";
import CloseButtonForModal from "@components/common/CloseButtonForModal";

const wallets = [
  {
    type: WalletType.OKX,
    logo: OkxWalletLogo,
    detected: typeof window !== "undefined" && !!window.okxwallet,
    onClick: async () => {
      if (typeof window === "undefined") {
        return;
      }
      if (!window.okxwallet) {
        // open okx wallet website
        window.open("https://www.okx.com/web3", "_blank");
        return;
      }
      const okxAccount = await window.okxwallet?.bitcoin.connect();
      if (!okxAccount) {
        throw new Error("No Bitcoin Account");
      }
      PubSub?.publish(EventType.ON_CONNECT, {
        address: okxAccount.address,
        connector: WalletType.OKX,
      });
    },
  },
  {
    type: WalletType.Unisat,
    logo: UnisatWalletLogo,
    detected: typeof window !== "undefined" && !!window.unisat,
    onClick: async () => {
      if (typeof window === "undefined") {
        return;
      }

      if (!window.unisat) {
        // open unisat wallet website
        window.open("https://unisat.io", "_blank");
        return;
      }
      const addresses = await window.unisat?.requestAccounts();
      if (!addresses.length) {
        throw new Error("No Bitcoin Account");
      }
      PubSub?.publish(EventType.ON_CONNECT, {
        address: addresses[0],
        connector: WalletType.Unisat,
      });
    },
  },
  {
    type: WalletType.Xverse,
    logo: XverseWalletLogo,
    detected: typeof window !== "undefined" && !!window.XverseProviders,
    onClick: async () => {
      if (typeof window === "undefined") {
        return;
      }

      if (!window.XverseProviders) {
        // open unisat wallet website
        window.open("https://www.xverse.app", "_blank");
        return;
      }

      const response = await request("getAccounts", {
        purposes: [AddressPurpose.Ordinals],
      });

      if (response.status === "success") {
        const ordAddressItem = response.result.find(
          (address) => address.purpose === AddressPurpose.Ordinals,
        );
        if (ordAddressItem) {
          PubSub?.publish(EventType.ON_CONNECT, {
            address: ordAddressItem.address,
            connector: WalletType.Xverse,
          });
        } else {
          throw new Error("No Bitcoin Account");
        }
      } else {
        if (response.error.code === RpcErrorCode.USER_REJECTION) {
          throw new Error("User Rejected");
        } else {
          throw new Error("Failed to get accounts");
        }
      }
    },
  },
];

export default function BTCWalletKitModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const textColor = useColorModeValue("gray.800", "gray.100");
  const boxWrapperBg = useColorModeValue("gray.200", "gray.800");

  const toast = useToast();
  return (
    <Modal isOpen={open} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius={8}
        margin={{ base: 0 }}
        alignSelf={{ base: "flex-end", md: "center" }}
      >
        <ModalHeader color={textColor}>Connect Bitcoin Wallet</ModalHeader>
        <CloseButtonForModal />
        <ModalBody pb={6} color={textColor}>
          {wallets.map((wallet) => {
            return (
              <HStack
                key={wallet.type}
                gap={4}
                px={4}
                py={2}
                borderRadius={8}
                cursor="pointer"
                onClick={async () => {
                  try {
                    await wallet.onClick();
                    onClose();
                  } catch (error) {
                    toast({
                      description: (error as Error).message,
                      status: "error",
                      duration: 5000,
                    });
                  }
                }}
                _hover={{
                  bg: boxWrapperBg,
                }}
              >
                <Image
                  src={wallet.logo}
                  alt={wallet.type}
                  boxSize={16}
                  borderRadius={8}
                />
                <VStack alignItems="flex-start" gap={0}>
                  <Text fontWeight={600} fontSize={24} color={textColor}>
                    {wallet.type}
                  </Text>
                  {wallet.detected && (
                    <Badge colorScheme="purple">Detected</Badge>
                  )}
                </VStack>
              </HStack>
            );
          })}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
