const PREFIX = "IWK";

export enum EventType {
  ON_CONNECT = PREFIX + "ON_CONNECT",
  ON_DISCONNECT = PREFIX + "ON_DISCONNECT",
  ON_TRANSFER = PREFIX + "ON_TRANSFER",
  ON_ERROR = PREFIX + "ON_ERROR",
  ON_BALANCE_CHANGE = PREFIX + "ON_BALANCE_CHANGE",
  ON_MANUAL_INPUT = PREFIX + "MANUAL_INPUT",
}

export enum WalletType {
  Plug = "Plug",
  Stoic = "Stoic",
}
